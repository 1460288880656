<template>
  <QuestionnaireBase :title="title" :form="form" :finishFunction="finishFunction">
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../loadHelpers";
import {httpQuestionnaire} from "@/services";

import QuestionnaireBase from "@/components/common/questionnaires/questionnaire";
import BankDetails from "@/components/common/questionnaires/fieldsets/storage/BankDetails";

export default {
  name: "Storage",
  mixins: [loadHelpers],
  components: {
    QuestionnaireBase,
    BankDetails
  },
  data() {
    return {
      name: 'Storage',
      title: "Document Storage Setup",
      url: 'estate_planning_storage',
      order: [ // imported components required here to load
        'BankDetails'
      ],
      finishFunction: this.instructionsTaken,
      expiryAccessCodeOnFinish: true
    };
  },
  methods: {
    async instructionsTaken() {
      return httpQuestionnaire
          .patch(this.url, {
            storage_id: this.form.id,
            status: "awaiting_setup",
          })
          .then((response) => {
            console.log(response.data);
            return true
          })
          .catch((error) => {
            console.log(error);
            return false
          });
    }
  }
};
</script>
