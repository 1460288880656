<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Bank Details</h3>

    <b-alert v-if="form.has_existing_details" show variant="danger">
      We already have you bank account details on file, to request a change to these please resubmit this form.
    </b-alert>
    <p v-else>To setup document storage we require your bank account details for a direct debit.</p>
    <AccountName v-model="form.account_name" />
    <SortCode v-model="form.sort_code" />
    <AccountNumber v-model="form.bank_number" />

  </div>
</template>

<script>
import { fieldsetHelpers } from "../fieldsetHelpers";

import AccountName from "@/components/common/questionnaires/question/questions/storage/AccountName";
import AccountNumber from "@/components/common/questionnaires/question/questions/storage/AccountNumber";
import SortCode from "@/components/common/questionnaires/question/questions/storage/SortCode";

export default {
  name: 'BankDetails',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    SortCode,
    AccountNumber,
    AccountName

  },
  data () {
    return {
      label: 'Bank Details' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  }
};
</script>
