<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="!!data">
    <InputBasic
        v-model="data"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../helpers/questionLoadHelpers";

import QuestionBase from "../../QuestionBase";
import InputBasic from "../../inputs/InputBasic";

export default {
  name: 'SortCode',
  mixins: [questionLoadHelpers],
  components: {
    InputBasic,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    id: {
      type: Number,
      required: false
    },
    formPath: {
      type: String,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'What is your bank sort code?',
        subTitle:  null,
        tip: null
      },
      db: {
        saveLocation: 'product_storage',
        saveField: 'sort_code',
        objectId: this.id,
        formPath: this.formPath
      },
      args: {
        placeholder: 'e.g. 12-42-90',
        label: null,
        type: 'text',
        skippable: false,
        required: true,
        inputClass: 'field-50'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
